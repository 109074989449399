import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

import { Cog6ToothIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ChannelModel } from '@mainApp/src/stores/Channel.model';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';
import { useTranslation } from 'react-i18next';

import {
  useGetActiveCommunityDefaultChannelRedirect,
  useIsInExactChannel,
} from '@mainApp/src/hooks';

import { ChannelGroupModeEnum } from './channelGroupContextMenuContext';

import { classNames } from '@foundationPathAlias/utilities';
import {
  DeleteModaChannelContent,
  DeleteModaChannelGroupContent,
  MenuItem,
  Separator,
} from '@mainApp/src/components/common';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';

export enum MenuActionsEnum {
  READ = 'READ',
  SETTINGS = 'SETTINGS',
  DELETE = 'DELETE',
}

type Props = {
  rootCn?: string;
  close?: () => void;
  onAction?: (action: MenuActionsEnum) => void;
  mode: ChannelGroupModeEnum;
  data: ChannelGroupModel | ChannelModel | null;
  noSeparator?: boolean;
};
export function _Menu(props: Props) {
  const { close, mode, data, rootCn, noSeparator, onAction } = props;
  const {
    communityStore,
    messageStore,
    channelStore,
    channelGroupSettingsStore,
    channelSettingsStore,
    interactiveStore,
  } = useMultipleInjection([
    IOC_TOKENS.communityStore,
    IOC_TOKENS.messageStore,
    IOC_TOKENS.channelGroupSettingsStore,
    IOC_TOKENS.channelSettingsStore,
    IOC_TOKENS.channelStore,
    IOC_TOKENS.interactiveStore,
  ]);

  const { t } = useTranslation(['common', 'channel']);
  const isInExactChannel = useIsInExactChannel(
    data instanceof ChannelModel ? data.serverData.id : undefined
  );

  const activeCommunity = communityStore.activeCommunity.data;

  const communityId = activeCommunity?.serverData?.id;

  const redirectToDefaultChannel =
    useGetActiveCommunityDefaultChannelRedirect();

  let readMenulabel = t('markAllAsRead');
  let readMenuAction = () => {
    const channelModel = data as ChannelGroupModel;
    channelStore.channelGroupMarkAllChannelsAsRead(
      communityId as string,
      channelModel.id
    );
    close?.();
  };

  let settingsMenuLabel = t('channel:channelGroupSettings.label');
  let settingsMenuAction = () => {
    channelGroupSettingsStore.setChannelGroupModel(data as ChannelGroupModel);
    channelGroupSettingsStore.setIsShow(true);
    close?.();
  };

  let deleteMenuLabel = t('channel:deleteChannelGroup');
  let deleteMenuAction = () => {
    if (!data) {
      throw new Error(`data is not defined`);
    }
    if (!(data instanceof ChannelGroupModel)) {
      throw new Error(`data is not instance of ChannelGroupModel`);
    }

    close?.();

    interactiveStore.setActiveContentType(
      InteractiveContentTypesEnum.OTHER,
      true
    );
    interactiveStore.setContent(
      <DeleteModaChannelGroupContent
        channelGroupModel={data}
        // onProceed={(isError) => {
        //   if (!isError && isInExactChannel) {
        //     // should redirect to the default only if the user in the channel that he is deleting. At the moment of next.js hot realod in dev mode this hook will return a null because the community at that moment is not defined. It will be defined after the hot reload is done.
        //     redirectToDefaultChannel?.();
        //   }
        // }}
      />
    );

    interactiveStore.setInteractiveElementOpen(true);
  };

  if (mode === ChannelGroupModeEnum.CHANNEL) {
    readMenulabel = t('markAsRead');
    readMenuAction = () => {
      messageStore.markMessagesAllAsRead();
      close?.();
    };

    settingsMenuLabel = t('channel:channelSettings.label');
    settingsMenuAction = () => {
      channelSettingsStore.setChannelModel(data as ChannelModel);
      channelSettingsStore.setIsShow(true);
      close?.();
    };

    deleteMenuLabel = t('channel:deleteChannel');
    deleteMenuAction = async () => {
      if (!data) {
        throw new Error(`data is not defined`);
      }
      if (!(data instanceof ChannelModel)) {
        throw new Error(`data is not instance of ChannelModel`);
      }

      close?.();

      interactiveStore.setActiveContentType(
        InteractiveContentTypesEnum.OTHER,
        true
      );
      interactiveStore.setContent(
        <DeleteModaChannelContent
          channelServerData={data.serverData}
          onProceed={(isError) => {
            if (!isError && isInExactChannel) {
              // should redirect to the default only if the user in the channel that he is deleting. At the moment of next.js hot realod in dev mode this hook will return a null because the community at that moment is not defined. It will be defined after the hot reload is done.
              redirectToDefaultChannel?.();
            }
          }}
        />
      );

      interactiveStore.setInteractiveElementOpen(true);
    };
  }

  return (
    <ul
      className={classNames(
        //TODO:Branding
        'rounded-[5px] border-[1px]  border-element-subtle py-[8px] shadow-shadow-menu-dekstop dark:border-element-subtle-dark',
        rootCn
      )}
    >
      {(activeCommunity?.isUserAdmin || activeCommunity?.isUserOwner) && (
        <>
          <MenuItem
            icon={<EyeIcon />}
            label={readMenulabel}
            onClick={() => {
              readMenuAction();
              onAction?.(MenuActionsEnum.READ);
            }}
          />
          {!noSeparator && <Separator />}
        </>
      )}

      {(activeCommunity?.isUserAdmin || activeCommunity?.isUserOwner) && (
        <>
          <MenuItem
            icon={<Cog6ToothIcon />}
            label={settingsMenuLabel}
            onClick={() => {
              settingsMenuAction();
              onAction?.(MenuActionsEnum.SETTINGS);
            }}
          />

          {!noSeparator && <Separator />}
          <MenuItem
            icon={<TrashIcon className="text-element-error" />}
            className="text-element-error"
            label={deleteMenuLabel}
            onClick={() => {
              deleteMenuAction();
              onAction?.(MenuActionsEnum.DELETE);
            }}
          />
        </>
      )}
    </ul>
  );
}

export const Menu = observer(_Menu);
