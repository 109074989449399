import {
  SquareIconButtonBaseTooltip,
  SquareIconButtonBaseTooltipPropsType,
} from '@foundationPathAlias/components/buttons/SquareIconButtonBaseTooltip';
import { PlusIcon } from '@heroicons/react/24/outline';

export type PlusBtnPropsType = Omit<
  SquareIconButtonBaseTooltipPropsType,
  'children'
>;

export function PlusBtnTooltip(props: PlusBtnPropsType) {
  return (
    <SquareIconButtonBaseTooltip {...props}>
      <PlusIcon className="text-buttonsAndIcons-iconColor-onSecondary" />
    </SquareIconButtonBaseTooltip>
  );
}
