import { classNames } from '@foundationPathAlias/utilities';
import { SidebarChannelsColumn } from './SidebarChannelsColumn';
import { SidebarCommunitiesColumn } from './SidebarCommunitiesColumn';

type Props = {
  /** on some pages like Explore there shouldn't be rendered channels and active community */
  simplifiedMode?: boolean;
};

export function Sidebar(props: Props) {
  const { simplifiedMode } = props;
  return (
    <nav
      className={classNames(
        'flex h-full overflow-x-hidden bg-baseTheme-background-product'
      )}
    >
      <SidebarCommunitiesColumn simplifiedMode={simplifiedMode} />

      {!simplifiedMode && <SidebarChannelsColumn />}
    </nav>
  );
}
