import { IOC_TOKENS, useInjection } from '@mainApp/src/ioc';
import { useEffect } from 'react';

import { CommunityEdge, PaginatedData } from '@10x/foundation/types';
import { GET_USER_COMMUNITIES } from '@mainApp/src/graphql/queries';
import { useQuery } from 'urql';

import { GetCommunitiesQueryVariables } from '@mainApp/src/stores/Community.store.types';

export const useGetUserCommunitiesPaginated = (
  variables: GetCommunitiesQueryVariables,
  startQuery?: boolean
) => {
  const queryResponse = useQuery<{
    userCommunities: PaginatedData<CommunityEdge>;
  }>({
    query: GET_USER_COMMUNITIES,
    variables,
    pause: !startQuery,
  });
  const communityStore = useInjection(IOC_TOKENS.communityStore);
  const [queryData] = queryResponse;

  useEffect(() => {
    communityStore.setUserCommunities(queryData);
  }, [queryData]);

  if (!queryData.data) {
    return queryResponse;
  }

  return {
    originalResponse: queryResponse,
    storeData: communityStore.userCommunities,
  };
};
