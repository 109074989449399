import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { observer } from 'mobx-react-lite';

import {
  ArrowRightOnRectangleIcon,
  Cog6ToothIcon,
  EyeIcon,
  PlusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { MenuItem, Separator } from '@mainApp/src/components/common';
import { useCopyCurrentUrlClipboard } from '@mainApp/src/hooks';
import { CommunityModel } from '@mainApp/src/stores/Community.model';
import { useTranslation } from 'react-i18next';

type Props = {
  close: () => void;
};
export function _Menu(props: Props) {
  const { close } = props;
  const { capStore, communityStore, createChannelOrGroupStore } =
    useMultipleInjection([
      IOC_TOKENS.capStore,
      IOC_TOKENS.communityStore,
      IOC_TOKENS.createChannelOrGroupStore,
    ]);

  const { t } = useTranslation(['common', 'cap']);

  const activeCommunity = communityStore.activeCommunity.data;

  const copyCurrentUrlToClipboard = useCopyCurrentUrlClipboard();

  async function copyUrl() {
    copyCurrentUrlToClipboard();
    close();
  }

  return (
    <ul className="rounded-[5px] border-element-subtle py-[8px] shadow-shadow-menu-dekstop dark:border-element-subtle-dark">
      {(activeCommunity?.isUserAdmin || activeCommunity?.isUserOwner) && (
        <>
          <MenuItem
            icon={<EyeIcon />}
            label={t('markAllAsRead')}
            onClick={() => {
              alert('Mark all as read in the community');
              close();
            }}
          />
          <Separator />
        </>
      )}

      <MenuItem
        icon={<UserPlusIcon />}
        label={t('invitePeople')}
        onClick={copyUrl}
      />
      {(activeCommunity?.isUserAdmin || activeCommunity?.isUserOwner) && (
        <>
          <MenuItem
            icon={<PlusIcon />}
            label={t('createChannelGroup')}
            onClick={() => {
              createChannelOrGroupStore.show('group');
              close();
            }}
          />
          <Separator />

          <MenuItem
            icon={<Cog6ToothIcon />}
            label={t('cap:communitySettings')}
            onClick={() => {
              capStore.setIsShow(true);
              close();
            }}
          />
        </>
      )}

      {/*  
      {activeCommunity?.isUserOwner && (
        <MenuItem
          icon={<BellIcon />}
          label={t('notificationSettings')}
          onClick={() => {
            alert('Notification settings');
          }}
        />
      )}
        */}

      {(activeCommunity?.isUserMember || activeCommunity?.isUserAdmin) &&
        !activeCommunity?.isUserOwner && (
          <>
            <Separator />
            <MenuItem
              className="text-element-error"
              icon={
                <ArrowRightOnRectangleIcon className="text-element-error" />
              }
              label={t('leaveCommunity')}
              onClick={() => {
                const { id, slugName } = (activeCommunity as CommunityModel)
                  .serverData;
                communityStore.leaveCommunity(id, slugName as string);
                close();
              }}
            />
          </>
        )}
    </ul>
  );
}

export const Menu = observer(_Menu);
