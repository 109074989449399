import { SidebarChannelHeading } from '@foundationPathAlias/components';
import { classNames } from '@foundationPathAlias/utilities';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChannelGroupModeEnum,
  useChannelGroupContextMenuContext,
} from './channel-group-context-menu';

type Props = {
  channelGroupModel: ChannelGroupModel;
  onPlusClick: (channelGroupId: string) => void;
  children: React.ReactNode;
  noLeftIcon?: boolean;
  hideRightActions?: boolean;
  cn?: string;
};

export function ChannelGroupHeadingWithMenu(props: Props) {
  const {
    channelGroupModel,
    onPlusClick,
    noLeftIcon,
    hideRightActions,
    children,
    cn,
  } = props;

  const i18n = useTranslation('channel');
  const { t } = i18n;

  const {
    communityStore,
    dimensionsStore: { isMobile },
  } = useMultipleInjection([
    IOC_TOKENS.communityStore,
    IOC_TOKENS.dimensionsStore,
  ]);

  const isUserAdminOrOwner =
    communityStore.activeCommunity.data?.isUserAdminOrOwner;

  const channelGroupId = channelGroupModel.id;
  const channelGroupName = channelGroupModel.name;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const channelGroupContextMenuCtx = useChannelGroupContextMenuContext();

  const showContextMenu = useCallback(
    (e: React.SyntheticEvent<Element, Event>) => {
      if (!isUserAdminOrOwner) return;
      e.preventDefault();
      channelGroupContextMenuCtx.showContextMenu(
        anchorEl as HTMLElement,
        ChannelGroupModeEnum.GROUP,
        channelGroupModel
      );
    },
    [anchorEl, isUserAdminOrOwner]
  );

  return (
    <SidebarChannelHeading
      key={channelGroupId}
      cn={classNames('my-[12px] w-full', cn)}
      headingCn={isMobile ? 'pr-[16px]' : undefined}
      label={channelGroupName}
      isMobile={isMobile}
      noRightActions={!isUserAdminOrOwner || hideRightActions}
      dotsBtnTooltipContent={t<string>('channelGroupSettings.label')}
      plusBtnTooltipContent={t<string>('modal.createChannel')}
      onPlusClick={() => onPlusClick(channelGroupId)}
      onDotsClick={showContextMenu}
      getHeadingRef={(anchorEl) => {
        setAnchorEl(anchorEl);
      }}
      onContextMenu={showContextMenu}
      onLongPress={showContextMenu}
      noLeftIcon={noLeftIcon}
    >
      {children}
    </SidebarChannelHeading>
  );
}
