import { Sidebar } from '@mainApp/src/components/sidebar/Sidebar';
import { AccountSettings } from '@mainApp/src/modules/account-settings';
import { observer } from 'mobx-react-lite';

import { useAuthUI } from '@mainApp/src/components/auth/useAuthUI';
import { usePostAuthOnboardingUI } from '@mainApp/src/components/post-auth-onboarding/usePostAuthOnboardingUI';
import { useCommunityColors } from '@mainApp/src/hooks';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import dynamic from 'next/dynamic';
import { useRef } from 'react';

import _throttle from 'lodash/throttle';

import { systemConfig } from '@mainApp/src/config';
import { GlobalEvents } from '@mainApp/src/events';

const SidebarFNDNoSSR = dynamic(
  () =>
    import('@10x/foundation/src/components/sidebar/Sidebar').then(
      (mod) => mod.Sidebar
    ),
  {
    ssr: false,
  }
);

type Props = {
  children: React.ReactNode;
  simplifiedMode?: boolean;
};

export function _SidebarLayout(props: Props) {
  const { children, simplifiedMode } = props;
  useAuthUI();
  usePostAuthOnboardingUI();
  useCommunityColors();

  const { systemStore, dimensionsStore, eventBus } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.eventBus,
  ]);
  const isDesktop = !dimensionsStore.isMobile;

  const contentScrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    eventBus.emit(GlobalEvents.PRIMARY_SIDEBAR_CONTENT_SCROLL);

    const target = e.target as HTMLDivElement;
    const clientH = target.clientHeight;
    const scrollH = target.scrollHeight;
    const scrollY = target.scrollTop;
    const scrollDelta = scrollH - scrollY - clientH;

    if (scrollDelta < systemConfig.scrollToBottomThreshold) {
      eventBus.emit(GlobalEvents.PRIMARY_SIDEBAR_CONTENT_SCROLL_REACHED_BOTTOM);
    }
  };
  const throttledContentScrollHandlerRef = useRef(
    _throttle(contentScrollHandler, systemConfig.throttleDelay)
  );

  return (
    <div>
      <SidebarFNDNoSSR
        open={systemStore.isSidebarOpened}
        docked={isDesktop}
        onContentScroll={(e) => {
          throttledContentScrollHandlerRef.current(e);
        }}
        styles={{
          sidebar: {
            maxWidth: '324px',
            overflowY: isDesktop ? 'hidden' : 'auto',
          },
        }}
        contentClassName={`${isDesktop ? 'w-80%' : 'w-full'}`}
        onSetOpen={() => {
          systemStore.setIsSidebarOpened(!systemStore.isSidebarOpened);
        }}
        sidebarContent={<Sidebar simplifiedMode={simplifiedMode} />}
      >
        {children}
      </SidebarFNDNoSSR>
    </div>
  );
}

const SidebarLayout = observer(_SidebarLayout);

export const getSidebarLayout =
  (
    options = {
      simplifiedMode: false,
    }
  ) =>
  (page: React.ReactNode) =>
    (
      <SidebarLayout {...options}>
        {page}
        {/* TODO: maybe create a dedicated layout for the chats? */}
        <AccountSettings />
      </SidebarLayout>
    );
