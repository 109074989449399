import { ThreeDotsVerticalBtnTooltip } from '@foundationPathAlias/components/buttons/square';
import { classNames, useLongPress } from '@foundationPathAlias/utilities';
export type SidebarChannelItemPropsType = {
  cn?: string;
  /** a first element inside the root */
  contentWrapperCn?: string;
  lContentCn?: string;
  lContent: JSX.Element;
  children: string | React.ReactNode;
  newMessagesCount?: number | null;
  active?: boolean;
  /** the element that wraps the children with truncater el */
  childrenWrapperCn?: string;
  /** children truncate el cn */
  childrenTruncateCn?: string;
  /** a wrapper that wraps the right content */
  rightContentCn?: string;
  tooltipContent?: string;
  noDotsBtn?: boolean;
  onDotsBtnClick?: (e: React.SyntheticEvent<Element, Event>) => void;
  onPointerUp: () => void;
  onContextMenu?: (e: React.SyntheticEvent) => void;
  onLongPress?: (e: React.SyntheticEvent) => void;
  geRef?: (el: HTMLDivElement) => void;
};

import { useState } from 'react';
import { SquareBadge } from '../community-elements';

export function SidebarChannelItem(props: SidebarChannelItemPropsType) {
  const {
    lContent,
    lContentCn,
    children,
    childrenWrapperCn,
    childrenTruncateCn,
    cn,
    contentWrapperCn,
    rightContentCn,
    active,
    newMessagesCount,
    noDotsBtn,
    onPointerUp,
    onDotsBtnClick,
    onContextMenu,
    onLongPress,
    geRef,
    tooltipContent,
  } = props;

  const longPressHandlers = useLongPress(onLongPress as any);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={cn}
      onContextMenu={onContextMenu}
      onTouchStart={longPressHandlers.onTouchStart as any}
      onTouchEnd={longPressHandlers.onTouchEnd as any}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      ref={geRef}
    >
      <div
        className={classNames(
          `sidebar-channel-item animation-classic relative flex h-[32px] w-full cursor-pointer items-center pl-[18px] 
          text-buttonsAndIcons-textColor-onSecondary-default 
          hover:bg-buttonsAndIcons-fillColor-transparent-hover hover:text-buttonsAndIcons-textColor-onSecondary-hover`,
          active &&
            'bg-buttonsAndIcons-fillColor-transparent-activeSelected text-text-primary',
          contentWrapperCn
        )}
        onPointerUp={onPointerUp}
      >
        <div
          className={classNames(
            'text-buttonsAndIcons-fillColor-transparent mr-[8px] h-[16px] w-[16px] hover:text-buttonsAndIcons-textColor-onSecondary-hover',
            active && 'text-buttonsAndIcons-textColor-onSecondary-active',
            lContentCn
          )}
        >
          {lContent}
        </div>

        <div
          className={classNames('relative h-full flex-1', childrenWrapperCn)}
        >
          <p
            className={classNames(
              'absolute left-0 top-[4px] w-full overflow-hidden text-ellipsis text-left text-body16R leading-[24px]',
              (newMessagesCount || active) && 'text-body16SB text-text-primary',
              childrenTruncateCn
            )}
          >
            {children}
          </p>
        </div>

        <div
          className={classNames(
            'mr-[8px] flex items-center space-x-[2px]',
            rightContentCn
          )}
        >
          {newMessagesCount && <SquareBadge>{newMessagesCount}</SquareBadge>}
          {!noDotsBtn && isHovered && (
            <ThreeDotsVerticalBtnTooltip
              className={'h-[24px] w-[24px]'}
              onPointerUp={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDotsBtnClick?.(e);
              }}
              darkMode={false}
              tooltipContent={tooltipContent as string}
            />
          )}
        </div>
      </div>
    </div>
  );
}
