type SquareBadgePropsType = {
  children: string | number;
};

export function SquareBadge(props: SquareBadgePropsType) {
  const { children } = props;

  return (
    <span className="text-utilities-white flex h-[20px] w-[25px] items-center justify-center rounded-[10px] bg-brand-hundredPercent text-sm14T leading-[inherit]">
      {children}
    </span>
  );
}
