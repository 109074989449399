import { observer } from 'mobx-react-lite';

import { ContextMenu } from '@foundationPathAlias/components';

import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { ChannelGroupModel } from '@mainApp/src/stores/ChannelGroup.model';
import { reaction } from 'mobx';
import { useEffect } from 'react';
import { Menu, MenuActionsEnum } from './Menu';
import {
  ChannelGroupModeEnum,
  useChannelGroupContextMenuContext,
} from './channelGroupContextMenuContext';

export function _GroupContextMenu() {
  const { isShow, anchorEl, mode, data, hideContextMenu } =
    useChannelGroupContextMenuContext();

  const {
    dimensionsStore: { isMobile },
    interactiveStore,
  } = useMultipleInjection([
    IOC_TOKENS.dimensionsStore,
    IOC_TOKENS.interactiveStore,
  ]);

  useEffect(() => {
    const disposer = reaction(
      () => {
        return interactiveStore.isBottomSheetOpened;
      },
      (isBottomSheetOpened) => {
        if (isMobile && !isBottomSheetOpened) {
          hideContextMenu();
        }
      }
    );

    return () => disposer();
  }, []);

  useEffect(() => {
    // handling the context menu on mobile and render it in bottomsheet
    if (!isMobile) return;

    if (isShow && data) {
      const title =
        mode === ChannelGroupModeEnum.GROUP
          ? (data as ChannelGroupModel).name
          : '';

      interactiveStore.setContent(
        <div className="flex flex-col">
          <h5 className="themed-text pb-[16px] pl-[24px] text-body18SB">
            {title}
          </h5>
          <Menu
            mode={mode}
            data={data}
            onAction={(action) => {
              switch (action) {
                case MenuActionsEnum.SETTINGS:
                  interactiveStore.setInteractiveElementOpen(false);
                  break;
                default:
                  break;
              }
            }}
            noSeparator
            rootCn="!shadow-none"
          />
        </div>
      );
      interactiveStore.setInteractiveElementOpen(true);
    }
  }, [isShow, isMobile]);

  // on mobile should be opened the bottomsheet for the context menu
  if (!anchorEl || isMobile) return null;

  return (
    <ContextMenu
      anchorEl={anchorEl}
      withoutAnchorAsTrigger
      isShow={isShow}
      onClose={hideContextMenu}
    >
      <Menu mode={mode} data={data} close={hideContextMenu} />
    </ContextMenu>
  );
}

export const GroupContextMenu = observer(_GroupContextMenu);
