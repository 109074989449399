import { getSidebarLayout } from '@mainApp/src/components/layouts/sidebar/SidebarLayout';

import { useDefaultChannelRedirectByQuery } from '@mainApp/src/hooks';

export default function Community() {
  useDefaultChannelRedirectByQuery();
  // there could be some loader if needed etc.
  return null;
}

Community.getLayout = getSidebarLayout();
