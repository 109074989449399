import { SidebarChannelItem } from '@foundationPathAlias/components';
import { getChannelIconByType } from '@mainApp/src/modules/channel/utilities';
import { ChannelModel } from '@mainApp/src/stores/Channel.model';
import { useCallback, useState } from 'react';
import {
  ChannelGroupModeEnum,
  useChannelGroupContextMenuContext,
} from './sidebar/channel-group-context-menu';

import { forwardRef } from 'react';

export type Props = {
  channelModel: ChannelModel;
  active: boolean;
  onPointerUp: () => void;
  newMessagesCount: number | null;
  tooltipContent: string;
  isUserAdminOrOwner: boolean;
};

export const ChannelItemWithMenu = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      channelModel,
      active,
      onPointerUp,
      newMessagesCount,
      tooltipContent,
      isUserAdminOrOwner,
      ...otherProps
    } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const channelGroupContextMenuCtx = useChannelGroupContextMenuContext();

    const showContextMenu = useCallback(
      (e: React.SyntheticEvent<Element, Event>) => {
        if (!isUserAdminOrOwner) return;
        e.preventDefault();
        channelGroupContextMenuCtx.showContextMenu(
          anchorEl as HTMLElement,
          ChannelGroupModeEnum.CHANNEL,
          channelModel
        );
      },
      [anchorEl, isUserAdminOrOwner]
    );

    const Icon = getChannelIconByType(channelModel.serverData.channelType);

    return (
      <div ref={ref} {...otherProps}>
        <SidebarChannelItem
          active={active}
          onDotsBtnClick={showContextMenu}
          noDotsBtn={!isUserAdminOrOwner}
          tooltipContent={tooltipContent}
          cn="flex-1"
          lContent={<Icon />}
          newMessagesCount={newMessagesCount || null}
          onPointerUp={onPointerUp}
          geRef={(anchorEl) => {
            setAnchorEl(anchorEl);
          }}
          onContextMenu={showContextMenu}
          onLongPress={showContextMenu}
        >
          {channelModel.serverData.name}
        </SidebarChannelItem>
      </div>
    );
  }
);
